<template>
  <div class="container-fluid">
    <div class="col-lg-5 col=12" style="padding: 0;">
      <h1 class="main-title">
        This page is under construction. <br />
        Check back soon.
      </h1>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container-fluid {
  padding: 3.5px 21.5px;
  padding-top: 80px;
  height: calc(100vh - 365px);
  background: #442671;
}
</style>
